import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const StackedBarChart = ({ data, name }: any) => {
  // Prepare data for Highcharts
  const options = {
    chart: {
      type: "bar",
      height: 300,
    },
    title: {
      text: name,
      align: "center",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: "transparent",
      labels: {
        enabled: false,
      },
      minorTickLength: 0,
      tickLength: 0,
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
      labels: {
        format: "{value}%",
      },
    },
    tooltip: {
      pointFormat:
        '<span style="color:{series.color}">{series.name}</span>' +
        ": <b>{point.y}</b> ({point.percentage:.0f}%)<br/>",
      shared: true,
    },
    plotOptions: {
      bar: {
        stacking: "percent",
        pointWidth: 30,
        dataLabels: {
          enabled: true,
          format: "{point.percentage:.0f}%",
        },
      },
    },
    series: data?.map((answer: any) => {
      return {
        name: answer.name,
        data: [answer.count],
        color: `${
          answer.name.toLowerCase() === "Yes".toLowerCase()
            ? "#2596be"
            : answer.name.toLowerCase() === "No".toLowerCase()
            ? "#c0504d"
            : ""
        }`,
      };
    }),
    accessibility: {
      enabled: false,
    },
  };

  return (
    <div className="custom-graph-container">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default StackedBarChart;
