import React from "react";
import "./assessmentForm.css";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  nextQuestionCall,
  setQuestionOverlayFalse,
  setQuestionOverlayTrue,
  submitAnswerOption,
} from "../../redux/features/questionList/questionListSlice";
import { setAssessmentTool } from "../../redux/features/assessmentTool/assessmentToolSlice";
import { submitAnswer } from "../../services/targetHealthcare";
import { toast } from "react-toastify";
import { QUESTION_TYPES } from "../../constants";

interface chk_props {
  image: string;
  text: string;
  bg_color?: string;
  border_color?: string;
  text_small: boolean;
  to?: string;
  radio_button_id?: string;
  alt?: string;
  currentSelectedState?: any;
  active?: boolean;
  categoriesSelection?: boolean;
  id?: Number;
  categorySelection?: boolean;
  answersSubmit?: boolean;
  questionId?: number;
  answerId?: number;
}
const CustomizedCheckbox = ({
  image,
  text,
  text_small,
  to,
  radio_button_id = "",
  alt,
  active,
  id,
  categorySelection,
  answersSubmit,
  questionId,
  answerId,
}: chk_props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentQuestionIndex = useAppSelector(
    (state) => state.questionList.currentQuestionIndex
  );
  const nextQuestionIndex = useAppSelector(
    (state) => state.questionList.nextQuestionIndex
  );
  const uuid = useAppSelector((state) => state.userData.uuid);
  const allQuestions = useAppSelector(
    (state) => state.questionList.allQuestions
  );
  const submitQuestionCall = async () => {
    let obj = {
      answer: answerId,
      question: questionId,
      text_response: allQuestions[currentQuestionIndex]?.text_response,
      user: uuid,
    };
    dispatch(setQuestionOverlayTrue());
    const submitAnswerApi = await submitAnswer(obj);
    if (submitAnswerApi?.status === 200 || submitAnswerApi?.status === 201) {
      dispatch(setQuestionOverlayFalse());
      dispatch(nextQuestionCall());
      if (nextQuestionIndex === allQuestions.length) {
        navigate("/report-page");
      }
    } else {
      dispatch(setQuestionOverlayFalse());
      toast.error("Error submitting data", { toastId: "toastId" });
    }
  };
  const routeChange = () => {
    if (categorySelection) {
      dispatch(setAssessmentTool({ text, id }));
    } else if (answersSubmit) {
      if (allQuestions[currentQuestionIndex].type.toLowerCase() !== QUESTION_TYPES.both) {
        dispatch(submitAnswerOption(text));
        setTimeout(() => {
          submitQuestionCall();
        }, 500);
      } else if (allQuestions[currentQuestionIndex].type.toLowerCase() === QUESTION_TYPES.both) {
        dispatch(submitAnswerOption(text));
        if (text !== allQuestions[currentQuestionIndex]?.free_text_option) {
          setTimeout(() => {
            submitQuestionCall();
          }, 500);
        }
      } else {
        dispatch(submitAnswerOption(text));
        setTimeout(() => {
          submitQuestionCall();
        }, 500);
      }
    }
  };

  return (
    <>
      <div
        className="customized-square-box flex flex-col sm:mb-4 sm:min-w-56 md:min-w-56 lg:min-w-64 cursor-pointer"
        tabIndex={0}
        onClick={routeChange}
      >
        <div className="outside-circle ml-auto">
          <button
            style={{
              backgroundColor: active ? "#AF1821" : "#DCDCDC",
            }}
            id={radio_button_id}
            aria-label={text}
            className={`small-circle rounded-full ml-auto`}
          ></button>
        </div>
        <div className="flex flex-col justify-center items-center h-full">
          {image ? (
            <div className="img-div mb-4">
              <img
                src={image}
                alt={alt}
                className={` ${
                  id === 2 ? "gp-img" : id === 4 ? "phar-img" : "ccg-img"
                }`}
              />
            </div>
          ) : (
            ""
          )}
          <div>
            <div
              className={`${
                text_small ? "text-semiGray" : "text-black text-sm"
              }`}
            >
              {text}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CustomizedCheckbox;
