import React from "react";
import SubmitButton from "../common/SubmitButton";
import { useNavigate } from "react-router-dom";

const HomePage1: React.FC = () => {
  const navigate = useNavigate();
  const navigateToHomePage2 = () => {
    navigate("/page-2")
  }
  return (
    <div className="container mx-auto text-center ">
      <h1 className="mb-4 text-3xl xxl:text-5xl text-red font-sans font-semibold">
        The Self Assessment Checklist
      </h1>
      <div className="p-4 mt-4 rounded-lg text-center ml-3">
        <p className="text-gray sm:lg:md:xl:leading-6 xxl:text-3xl">
          The TARGET Self-Assessment Checklist (SAC) aims to support users to
          reflect on antimicrobial stewardship and antibiotic prescribing in
          Primary Care settings.
          <br />
          <br />
          It should take no longer than 10 minutes to complete.
          <br />
          <br />
          Regular reflection on antibiotic prescribing behaviour is encouraged
          therefore, the digital tool can be used as many times as you would
          like but must be used in one sitting (you cannot close the web page
          and return without having to re-input information).
          <br />
          <br />
          <a
          href="/page-2"
          onMouseDown={navigateToHomePage2}
            className="text-red font-semibold underline cursor-pointer"
          >
            Data Use
          </a>
          {/* The checklist for general practice settings applies generally to
          England, Northern Ireland, Scotland and Wales. The checklist for
          commissioners and community pharmacy is currently applicable to
          England only, content tailored for the other nations is currently
          being developed. */}
        </p>
      </div>

      <div className="row mb-4"></div>
      <SubmitButton
        onClickHandler={() => {}}
        text="Continue"
        buttonLabel="button Continue"
        to="/lets-start"
      />
    </div>
  );
};
export default HomePage1;
