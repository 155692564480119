import { configureStore } from "@reduxjs/toolkit";
import assessmentToolSlice from "./features/assessmentTool/assessmentToolSlice";

import userDataSlice from "./features/userData/userDataSlice";
import questionListSlice from "./features/questionList/questionListSlice";
// ...

export const store = configureStore({
  reducer: {
    assessmentTool: assessmentToolSlice,
    userData: userDataSlice,
    questionList: questionListSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
