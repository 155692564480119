import React from "react";
import SubmitButton from "../common/SubmitButton";
const HomePage2: React.FC = () => {
  return (
    <div className="container mx-auto text-center ">
      <h1 className="mb-4 text-3xl xxl:text-5xl text-red font-sans font-semibold">
        The Self Assessment Checklist
      </h1>
      <div className="p-4 mt-4 rounded-lg text-center ml-3">
        <p className="text-gray font-medium text-left sm:lg:md:xl:leading-6 xxl:text-3xl">
          Data use
          <br />
          <br />
          All data collected in the digital self-assessment tool will be
          anonymous.
          <br />
          <br />
          Responses cannot be linked back to you, your computer or your place of
          work.
          <br />
          <br />
          Do not put personal information (names, contact details, practice name
          etc…) in any of the free text questions.
          <br />
          <br />
          Data will be stored and used for general analytical purposes only, for
          example to calculate the percentage user response at national and
          regional level to each question.
          <br />
          <br />
          <ol className="list-decimal list-inside">
            Anonymous data may also be:
            <li>
              &nbsp;&nbsp;&nbsp;Shared with other public health and NHS bodies
              to help support antimicrobial stewardship initiatives in their
              areas.
            </li>
            <li>
              &nbsp;&nbsp;&nbsp;Used in UKHSA reports or publications in peer
              reviewed journals.
            </li>
          </ol>
          <br />
          <br />
          By completing the Self-Assessment Checklist, you are agreeing to your
          responses being used as outlined above. Please refer to the user guide
          for more information on this tool, its development, and further
          considerations.
        </p>
      </div>

      <div className="row mb-4"></div>
      <SubmitButton
        onClickHandler={() => {}}
        text="Continue"
        buttonLabel="button Continue"
        to="/lets-start"
      />
    </div>
  );
};
export default HomePage2;
