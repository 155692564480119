import React from "react";
import "./assessmentForm.css";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { addTextareaAnswer } from "../../redux/features/questionList/questionListSlice";
import { QUESTION_TYPES } from "../../constants";

interface textarea_props {
  id: string;
  placeholder: string;
  required: boolean;
  label?: string;
  value?: string;
  disabled?: boolean;
  padding: boolean;
}
const TextAreaElement = ({
  id,
  placeholder,
  padding,
  label,
  value,
  disabled = false,
}: textarea_props) => {
  const dispatch = useAppDispatch();
  const currentQuestionIndex = useAppSelector(
    (state) => state.questionList.currentQuestionIndex
  );
  const allQuestions = useAppSelector(
    (state) => state.questionList.allQuestions
  );
  const textSubmitClick = useAppSelector(
    (state) => state.questionList.textSubmitClick
  );
  const onChange = (e: any) => {
    dispatch(addTextareaAnswer(e.target.value));
  };
  const textAreaValue = () => {
    return allQuestions[currentQuestionIndex].text_response;
  };
  const border_condition = () => {
    return (
      textSubmitClick &&
      allQuestions[currentQuestionIndex].type.toLowerCase() === QUESTION_TYPES.text &&
      !allQuestions[currentQuestionIndex].text_response.trim().length
    );
  };

  return (
    <>
      <div
        className={`${
          padding ? "md:px-4" : ""
        } sm:mb-3 md:mb-0 py-2 bg-formGray rounded-t-lg`}
      >
        <textarea
          aria-label={label}
          onChange={(e) => onChange(e)}
          id="comment"
          rows={6}
          className="w-full p-4 md:text-lg sm:max-md:text-base text-black bg-grey border focus:ring-0 dark:text-black "
          placeholder=""
          required
          value={value ? value : textAreaValue()}
          disabled={disabled}
          style={{
            borderColor: !border_condition() ? "#DCDCDC" : "#AF1821",
          }}
        ></textarea>
      </div>
    </>
  );
};
export default TextAreaElement;
