import "./App.css";
import Footer from "./components/common/Footer";
import Header from "./components/common/Header";
import PageRoutes from "./routes/PageRoutes";
import { useCallback, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAppSelector } from "./redux/hooks";
import { useDispatch } from "react-redux";
import { showToasterContainer } from "./redux/features/userData/userDataSlice";
import { useLocation } from "react-router-dom";

function App() {
  const location = useLocation();
  const [addedListner, setAddedlistner] = useState<boolean | undefined>(undefined);
  const showToaster = useAppSelector((state) => state.userData.showToaster);
  const dispatch = useDispatch();
  
  const popstateventcall = useCallback((e: any) => {
    dispatch(showToasterContainer(false));
    e.preventDefault();
    alert("Your data might get lost");
  }, [dispatch]);
  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    console.log(window.location.pathname);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  useEffect(() => {
    if (
      location.pathname === "/questions" ||
      location.pathname === "/form-page" ||
      location.pathname === "/report-page"
    ) {
      if(addedListner === undefined) {
        //First time added
        window.addEventListener("popstate", popstateventcall, { once: true });
        setAddedlistner(true);
      }
      else if (addedListner !== undefined && !addedListner) {
        // already added so remove and add
        window.removeEventListener("popstate", popstateventcall);
        window.addEventListener("popstate", popstateventcall, { once: true });
        setAddedlistner(true);
      }
    } else {
      if(addedListner) {
        window.removeEventListener("popstate", popstateventcall);
        setAddedlistner(false);
      }
    }
  }, [location.pathname,addedListner,popstateventcall]);
  
  const handleBeforeUnload = (e: any) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    return message;
  };
 
  useEffect(() => {
    if (!showToaster) {
      dispatch(showToasterContainer(true));
    }
  }, [dispatch,showToaster]);
  return (
    <div className="App">
      <div className="layout sm:px-3 xs-px-0 md:px-2 xl:px-56 md:mt-4 ">
        <div>
          {showToaster ? (
            <ToastContainer autoClose={2000} hideProgressBar={true} />
          ) : (
            ""
          )}
          <Header />
          <PageRoutes />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
