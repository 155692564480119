import React, { useEffect } from "react";
import "./assessmentForm.css";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getFooterData } from "../../services/targetHealthcare";
import { setFooterData } from "../../redux/features/userData/userDataSlice";

const Footer = () => {
  const FooterData = useAppSelector(
    (state) => state.userData.footerData
  );
  const dispatch = useAppDispatch();
useEffect(()=>{
  if(!FooterData.length) {
    footerCall();
  }
},[])
  const footerCall = async () => {
    const footer_data = await getFooterData();
    if(footer_data?.status==200) {
      dispatch(setFooterData(footer_data.data));
    }

  }
  return (
    <div className="mx-auto">
      <div className="flex justify-center md:flex-row sm:flex-col md:gap-x-6 sm:mb-4 mt-4">
        <p className="text-semiGray sm:max-md:leading-5 md:leading-6 footer-text">
          Version: {(FooterData?.version?.length)?FooterData.version:"1.0"}
          
        </p>
        <p className="text-semiGray sm:max-md:leading-5 md:leading-6 footer-text">
          Published {(FooterData?.published?.length)?FooterData.published:"2024"}
        </p>
        <p className="text-semiGray sm:max-md:leading-5 md:leading-6 footer-text">
          {/* Review date: {new Date().toLocaleString("default", { month: "long" })}{" "}
          {new Date().getFullYear()} */}
          Review {(FooterData?.review?.length)?FooterData.review:"2029"}
        </p>
      </div>
    </div>
  );
};
export default Footer;
