import React, { ChangeEventHandler } from "react";
import "./assessmentForm.css";
import { useAppSelector } from "../../redux/hooks";

interface label_props {
  id: string;
  options: any;
  title?: string;
  onChangeHandler: ChangeEventHandler<HTMLSelectElement>;
  value: any;
  disableOption?: string;
  marginBottom?: string;
}

const SelectGroupElement = ({
  id,
  options,
  title,
  onChangeHandler,
  value,
  disableOption,
  marginBottom = "mb-4",
}: label_props) => {
  const submitLoader = useAppSelector((state) => state.userData.submitLoader);
  return (
    <>
      <select
        style={{
          borderColor:
            !value?.toString().trim().length && submitLoader
              ? "#AF1821"
              : "#DCDCDC",
        }}
        id={id}
        className={`border text-gray rounded-lg bg-white md:text-xs sm:max-md:text-base w-full py-4 ${
          marginBottom ? marginBottom : "mb-4"
        }`}
        aria-label={title}
        defaultValue={value}
        value={value}
        onChange={(e) => onChangeHandler(e)}
      >
        <option key={-1} value="">
          {disableOption}
        </option>
        {options?.map((option1: any, key: number) => {
          return (
            <optgroup label={option1.name}>
              {option1?.boards?.map((option: any, key: number) => {
                return (
                  <option key={key} value={option?.id}>
                    {option?.name}
                  </option>
                );
              })}
            </optgroup>
          );
        })}
      </select>
    </>
  );
};
export default SelectGroupElement;
