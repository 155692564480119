import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import StackedBarChart from "../common/StackedBarChart";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { submitReport } from "../../services/targetHealthcare";
import TextAreaElement from "../common/TextAreaElement";
import { toast } from "react-toastify";
import CustomizedOverlay from "../common/CustomizedOverlay";
import { QUESTION_TYPES } from "../../constants";

const ReportPage: React.FC = () => {
  interface REPORT_DATA {
    question_text: string;
    user_answer: string;
    answers_count: {
      name: string;
      count: number;
    }[];
    total_answers: number;
  }
  const [reportData, setReportData] = useState<REPORT_DATA[] | []>([]);
  const [reportLoading, setReportLoading] = useState(false);
  const categoryId = useAppSelector((state) => state.assessmentTool.value.id);
  const uuid = useAppSelector((state) => state.userData.uuid);
  
  useEffect(() => {
    const generateReportCall = async () => {
      let report_obj = {
        user: uuid,
        category: categoryId,
      };
      const submitReportApi = await submitReport(report_obj);
      setReportLoading(true);
      if (submitReportApi?.status === 200 || submitReportApi?.status === 201) {
        let arr = [];
  
        arr = submitReportApi.data;
        setReportData(arr);
        setReportLoading(false);
      } else {
        setReportLoading(false);
        toast.error("Error getting report data", { toastId: "toastId" });
      }
    }
    generateReportCall()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const conditional_rendering_option = (type: string) => {
    return type.toLowerCase() === QUESTION_TYPES.option || type.toLowerCase() === QUESTION_TYPES.both;
  };
  const conditional_rendering_graph = (type: any) => {
    return type.toLowerCase() === QUESTION_TYPES.option || type.toLowerCase() === QUESTION_TYPES.both;
  };
  const assessmentType = useAppSelector((state) => state.assessmentTool.value);
  return (
    <div
      className="md:container mx-auto text-center"
      style={{
        opacity: reportLoading ? "0.8" : "1",
      }}
    >
      <CustomizedOverlay isActive={reportLoading} />
      <h1 className="mb-4 text-3xl text-red font-sans font-semibold">
        Self-Assessment Checklist <br />
        {assessmentType.id === 2
          ? "Questions for General Practice"
          : assessmentType.id === 3
          ? "Questions for Commissioners"
          : "Community Pharmacy Questions"}
      </h1>
      <p className="text-center text-2xl mb-3">Your Responses</p>
      {reportData?.map((question: any, index: number) => {
        return (
          <div className="mb-3" key={index}>
            {/* question contents  */}
            <div className="flex flex-row text-semiGray">
              <p className="text-black md:text-lg question font-semibold font-sans text-left">
                {`${index + 1}.`}&nbsp;&nbsp;
              </p>
              <div className="grow">
                <p className="text-black md:text-lg question font-semibold font-sans text-left">
                  {question.question_text}
                </p>
                {/* option content */}
                {conditional_rendering_option(question?.question_type) ? (
                  <div className="options lg:flex flex-row my-4 text-semiGray">
                    {question?.answers_count_icb?.map(
                      (option: any, index: number) => {
                        return (
                          <div
                            className="grid grid-flow-col gap-3 justify-start md:mr-4 items-baseline"
                            key={index}
                          >
                            <p className="text-xl  leading-normal text-left">
                              {option.name}
                            </p>
                            <div className="squarebox-option">
                              {option.name === question.user_answer ? (
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  className="tick"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                ) : (
                  ""
                )}
                {/* graph contents  */}
                {conditional_rendering_graph(question?.question_type) ? (
                  <div className="options lg:flex sm:block flex-row  justify-center lg:border">
                    <div className="lg:w-1/2">
                      <StackedBarChart
                        data={question?.answers_count_icb}
                        name={`${question?.user_icb_board} average percentage response`}
                      />
                    </div>

                    <div className="lg:block sm:hidden vertical-line"></div>
                    <div className="lg:w-1/2">
                      <StackedBarChart
                        data={question?.answers_count_region}
                        name={`${question?.user_region} average percentage response`}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {/* text response contents  */}
                <>
                  {(question?.question_type.toLowerCase() === QUESTION_TYPES.both ||
                    question?.question_type.toLowerCase() === QUESTION_TYPES.text) &&
                  question?.text_response?.trim().length ? (
                    <div className="w-full">
                      <TextAreaElement
                        value={question?.text_response}
                        label="Your answer for the above asked question"
                        id="current_guidence"
                        placeholder=""
                        disabled={true}
                        required={false}
                        padding={false}
                      />
                    </div>
                  ) : question?.question_type.toLowerCase() === QUESTION_TYPES.both &&
                    !question?.text_response?.trim().length ? (
                    ""
                  ) : question?.question_type.toLowerCase() === QUESTION_TYPES.text &&
                    !question?.text_response?.trim().length ? (
                    <p className="text-xl  leading-normal text-left">N/A</p>
                  ) : (
                    ""
                  )}
                </>

                {/* DESCRIPTION CONTENT */}
                {question?.description ? (
                  <div className="mt-4 rounded-lg text-left">
                    <p
                      className="text-semiGray leading-6"
                      dangerouslySetInnerHTML={{
                        __html: question?.description,
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default ReportPage;
