import React, { useEffect } from "react";
import CustomizedCheckbox from "../common/CustomizedCheckbox";

import SubmitButton from "../common/SubmitButton";
import ProgressBar from "../common/ProgressBar";
import BackButton from "../common/BackButton";
import { useAppSelector } from "../../redux/hooks";

import TextAreaElement from "../common/TextAreaElement";
import CustomizedOverlay from "../common/CustomizedOverlay";
import { QUESTION_TYPES } from "../../constants";

const DynamicComponent: React.FC = () => {
  const questionOverlay = useAppSelector(
    (state) => state.questionList.questionOverlay
  );
  const allQuestions = useAppSelector(
    (state) => state.questionList.allQuestions
  );
  const assessmentType = useAppSelector((state) => state.assessmentTool.value);
  const currentQuestionIndex = useAppSelector(
    (state) => state.questionList.currentQuestionIndex
  );
  const currentQuestion = allQuestions[currentQuestionIndex];

  useEffect(() => {}, []);
  const checkboxElement = (answers: any) => {
    return answers?.map((option: any, key: any) => {
      return (
        <CustomizedCheckbox
          questionId={currentQuestion?.id}
          answerId={option?.id}
          key={key}
          text={option?.answer}
          text_small={false}
          image=""
          active={option?.is_answer}
          answersSubmit={true}
        />
      );
    });
  };

  const textAreaElement = (props: any) => {
    return (
      <TextAreaElement
        label="Write your answer for the above asked question"
        id="current_guidence"
        placeholder=""
        required={false}
        padding={true}
      />
    );
  };
  const freeTextOptionClicked = (currentQuestion: any) => {
    return currentQuestion?.answers.some(
      (an: any) =>
        an?.is_answer === true && an?.answer === currentQuestion?.free_text_option
    );
  };
  return (
    <div
      className="container mx-auto text-center"
      style={{
        opacity: questionOverlay ? "0.8" : "1",
      }}
    >
      <div className="mb-4">
        <ProgressBar page={currentQuestionIndex + 1} />
      </div>
      <CustomizedOverlay isActive={questionOverlay} />
      <h1 className="mb-4 md:text-3xl sm:text-2xl text-red font-sans font-semibold">
        Self-Assessment Checklist <br />{" "}
        {assessmentType.id === 2
          ? "Questions for General Practice"
          : assessmentType.id === 3
          ? "Questions for Commissioners"
          : "Community Pharmacy Questions"}
      </h1>
      <p className="text-black md:text-lg question font-semibold font-sans text-left">
        {currentQuestionIndex != null ? currentQuestionIndex + 1 : ""}.{" "}
        {currentQuestion?.question}
      </p>
      {currentQuestion?.question_description ? (
        <div className="p-4 mt-4 rounded-lg text-left md:ml-3">
          <p
            className="text-semiGray leading-6"
            dangerouslySetInnerHTML={{
              __html: currentQuestion?.question_description,
            }}
          />
        </div>
      ) : (
        ""
      )}

      {currentQuestion?.type.toLowerCase() === QUESTION_TYPES.option ? (
        <div className="flex flex-wrap justify-center md:flex-row sm:flex-col md:gap-x-6 sm:mb-4 mt-4">
          {checkboxElement(currentQuestion?.answers)}
        </div>
      ) : currentQuestion?.type.toLowerCase() === QUESTION_TYPES.both ? (
        <>
          <div className="flex flex-wrap justify-center md:flex-row sm:flex-col md:gap-x-6 sm:mb-4 mt-4">
            {checkboxElement(currentQuestion?.answers)}
          </div>
          {freeTextOptionClicked(currentQuestion) ? (
            <>
              <p className="ounded-lg text-left md:ml-3 text-black md:text-lg question font-semibold font-sans text-left">
                {(currentQuestion.category === 2 && currentQuestion.id === 7) || (currentQuestion.category === 4 && currentQuestion.id === 30)
                  ? "What strategies do you currently use?"
                  : "Please Explain:"}
              </p>
              {textAreaElement(currentQuestion?.is_text)}
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        textAreaElement(currentQuestion?.is_text)
      )}

      {currentQuestion?.type.toLowerCase() === QUESTION_TYPES.option ? (
        <BackButton to="/form-page" />
      ) : currentQuestion?.type.toLowerCase() === QUESTION_TYPES.text ? (
        <div className="flex flex-row justify-between">
          <BackButton to="/form-page" />
          <SubmitButton
            onClickHandler={() => {}}
            questionId={currentQuestion?.id}
            answerId={currentQuestion?.answers[0]?.id}
            text="Submit"
          />
        </div>
      ) : currentQuestion?.type.toLowerCase() === QUESTION_TYPES.both &&
        freeTextOptionClicked(currentQuestion) ? (
        <div className="flex flex-row justify-between">
          <BackButton to="/form-page" />
          <SubmitButton
            onClickHandler={() => {}}
            questionId={currentQuestion?.id}
            answerId={currentQuestion?.answers[0]?.id}
            text="Submit"
          />
        </div>
      ) : (
        <BackButton to="/form-page" />
      )}
    </div>
  );
};
export default DynamicComponent;
