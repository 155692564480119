import React, { useEffect, useState } from "react";
import SubmitButton from "../common/SubmitButton";
import ProgressBar from "../common/ProgressBar";
import InputLabel from "../common/InputLabel";
import InputElement from "../common/InputElement";
import SelectElement from "../common/SelectElement";
import BackButton from "../common/BackButton";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { shallowEqual } from "react-redux";
import {
  onChangePractice,
  onChangeRegion2,
  onChangeRegion1,
  onChangeRole,
  updatePracticeList,
  updateRolesList,
  updateRegionList,
  onChangeOtherPractice,
  onChangeOtherRole,
  updateBoardList,
} from "../../redux/features/userData/userDataSlice";
import { getPractices, getRegions } from "../../services/targetHealthcare";
import { getRoles } from "../../services/targetHealthcare";
import SelectGroupElement from "../common/SelectGroupElement";
import CustomizedOverlay from "../common/CustomizedOverlay";

const FormPage: React.FC = () => {
  const [roleLoading, setRoleLoading] = useState(false);
  const [regionLoading, setRegionLoading] = useState(false);
  const [practiceLoading, setPracticeLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [
    userOtherPractice,
    userOtherRole,
    userPractice,
    userRole,
    userRegion1,
    userRegion2,
  ] = useAppSelector(
    (state) => [
      state.userData.otherPractice,
      state.userData.otherRole,
      state.userData.practice,
      state.userData.role,
      state.userData.region1_option,
      state.userData.region2_option,
    ],
    shallowEqual
  );
  const allQuestionsLength = useAppSelector(
    (state) => state.questionList.allQuestions.length
  );
  const role_list = useAppSelector((state) => state.userData.roleList);
  const practice_list = useAppSelector((state) => state.userData.practiceList);
  const region_list = useAppSelector((state) => state.userData.regionList);
  const region_boards_list = useAppSelector(
    (state) => state.userData.regionBoardList
  );
  const selectedCategoryId = useAppSelector(
    (state) => state.assessmentTool.value.id
  );

  useEffect(() => {
    let region_boards = region_list.filter((region: any) => {
      if (region.id.toString() === userRegion1) {
        return region.region_boards;
      }
      else {
        return false
      }
    });
    dispatch(updateBoardList(region_boards[0]?.region_boards));
  }, [userRegion1,dispatch,region_list]);

  const PracticeCall = async () => {
    setPracticeLoading(true);
    let practiceApi = await getPractices();
    if (practiceApi?.status === 200) {
      dispatch(updatePracticeList(practiceApi?.data));
      setPracticeLoading(false);
    } else {
      setPracticeLoading(false);
    }
  };
  const roleCall = async () => {
    setRoleLoading(true);
    let roleApi = await getRoles(selectedCategoryId);
    if (roleApi?.status === 200) {
      dispatch(updateRolesList(roleApi?.data));
      setRoleLoading(false);
    } else {
      setRoleLoading(false);
    }
  };
  const regionCall = async () => {
    setRegionLoading(true);
    let filtered_regions =
      selectedCategoryId === 2
        ? ""
        : selectedCategoryId === 3
        ? "?filter=GB"
        : "?filter=GB";
    let regionApi = await getRegions(filtered_regions);
    if (regionApi?.status === 200) {
      dispatch(updateRegionList(regionApi?.data));
      setRegionLoading(false);
    } else {
      setRegionLoading(false);
    }
  };
  useEffect(() => {
    if (!region_list?.length) {
      regionCall();
    }
    if (!practice_list?.length) {
      PracticeCall();
    }
    if (!role_list?.length) {
      roleCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (role_list.length && selectedCategoryId !== role_list[0].category) {
      dispatch(onChangeRole(""));
      dispatch(onChangeOtherRole(""));
      roleCall();
      dispatch(onChangeRegion1(""));
      dispatch(onChangeRegion2(""));
      regionCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategoryId]);
  const regionBoardHelperText = () => {
    return (
      (selectedCategoryId === 3 || selectedCategoryId === 4) &&
      parseInt(userRegion1) !== 6
    );
  };
  const handleSubmit = (e: any) => {};
  return (
    <div
      className="container mx-auto text-center"
      style={{
        opacity: roleLoading || regionLoading || practiceLoading ? "0.8" : "1",
      }}
    >
      <div className="mb-4">
        {allQuestionsLength ? <ProgressBar page={0} /> : ""}
      </div>
      <CustomizedOverlay
        isActive={roleLoading || regionLoading || practiceLoading}
      />
      <div className="max-w-sm mx-auto" role="form">
        <div className="mb-5 text-left">
          {/* region/country  */}
          <InputLabel
            id={"healthcare-board"}
            text={"Please select your ICB, HSCT or Health Board:"}
          />
          <SelectElement
            id="health-board"
            value={userRegion1}
            onChangeHandler={(e) => {
              dispatch(onChangeRegion1(e.target.value));
            }}
            disableOption="Choose a region"
            options={region_list}
            title="select regions"
          />

          {/* ICB Board */}
          {userRegion1 !== "6" ? (
            <>
              <SelectElement
                id="health-board-select-region2"
                value={userRegion2}
                onChangeHandler={(e) => {
                  dispatch(onChangeRegion2(e.target.value));
                }}
                title="select region boards"
                disableOption="Choose a region-board"
                options={region_boards_list}
                marginBottom="mb-0"
              />
              {/* {regionBoardHelperText() ? (
                <InputLabel
                  textSize="text-xs"
                  id={"healthcare-board-description"}
                  text={
                    "The content of this tool not currently tailored to commissioning or pharmacy settings in Scotland, Northern Ireland, and Wales. We hope to have this done soon."
                  }
                />
              ) : (
                ""
              )} */}
            </>
          ) : (
            <>
              <SelectGroupElement
                id="health-board-select-region2"
                value={userRegion2}
                onChangeHandler={(e) => {
                  dispatch(onChangeRegion2(e.target.value));
                }}
                title="select region boards"
                disableOption="Choose a region-board"
                options={region_boards_list}
                marginBottom="mb-0"
              />
              {regionBoardHelperText() ? (
                <InputLabel
                  textSize="text-xs"
                  id={"healthcare-board-description"}
                  text={
                    "The content of this tool not currently tailored to commissioning or pharmacy settings in Scotland, Northern Ireland, and Wales. We hope to have this done soon."
                  }
                />
              ) : (
                ""
              )}
            </>
          )}
          <div className="mb-4"></div>
          {/* types of practices */}
          {selectedCategoryId === 2 ? (
            <>
              <InputLabel id={"practices"} text={"Types of Practices"} />
              <SelectElement
                id="Types-of-Practices"
                value={userPractice}
                onChangeHandler={(e) => {
                  dispatch(onChangePractice(e.target.value));
                }}
                disableOption="Choose a practice type"
                options={practice_list}
                title="select Practice"
              />
              {userPractice === "5" ? (
                <>
                  <InputLabel
                    id={"other-practice"}
                    text={"Practice (free text response)"}
                  />
                  <InputElement
                    type="text"
                    title="Practice (free text response)"
                    value={userOtherPractice}
                    id="other-practice"
                    placeholder=""
                    onChangeHandler={(e) => {
                      dispatch(onChangeOtherPractice(e.target.value));
                    }}
                    required={false}
                  />
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
          {/* types of roles */}
          <InputLabel id={"roles"} text={"Roles"} />
          <SelectElement
            id="roles"
            value={userRole}
            onChangeHandler={(e) => {
              dispatch(onChangeRole(e.target.value));
            }}
            disableOption="Which best describes your role?"
            options={role_list}
            title="select user role"
          />
          {(selectedCategoryId === 2 && parseInt(userRole) === 22) ||
          (selectedCategoryId === 3 && parseInt(userRole) === 9) ||
          (selectedCategoryId === 4 && parseInt(userRole) === 30) ? (
            <>
              <InputLabel
                id={"other-role"}
                text={"Role (free text response)"}
              />
              <InputElement
                type="text"
                title="Role (free text response)"
                value={userOtherRole}
                id="other-role"
                placeholder=""
                onChangeHandler={(e) => {
                  dispatch(onChangeOtherRole(e.target.value));
                }}
                required={false}
              />
            </>
          ) : (
            ""
          )}
        </div>
        <div className="flex flex-row justify-between">
          <BackButton to="/lets-start" />
          <SubmitButton
            to="/questions"
            onClickHandler={handleSubmit}
            text="Submit"
          />
        </div>
      </div>
    </div>
  );
};
export default FormPage;
