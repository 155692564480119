export const post_code_regex = /[A-Z]{1,2}[0-9]{1,2}[A-Z]?\s?[0-9][A-Z]{2}/i;
export const positive_number_regex = /[1-9]\d*/;
export const gp_questions = [
  {
    id: 2,
    answers: [
      {
        id: 4,
        question: 2,
        answer: "Yes",
        is_text: false,
      },
      {
        id: 5,
        question: 2,
        answer: "No",
        is_text: false,
      },
    ],
    type: "Option",
    name: "GP practice",
    question:
      "Do you use national or local antibiotic guidance when considering how to treat common infections?",
    description:
      'You should be using local antibiotic guidance – this is usually based on national guidance produced by &nbsp<a href="https://www.gov.uk/government/collections/primary-care-guidance-diagnosing-and-managing-infections" class="text-red font-semibold underline">National Institute for Clinical Excellence (NICE) and UK Health Security Agency (UKHSA)</a>&nbsp; and may be modified locally by commissioners and microbiologists to localise the antibiotic guidance in accordance with local resistance and susceptibility patterns. This should be consistently used by all staff in your practice and out-of-hours services and can be found on the &nbsp<a href="https://www.nice.org.uk/guidance/health-protection/communicable-diseases/antimicrobial-stewardship" class="text-red font-semibold underline">NICE website</a>&nbsp;. The &nbsp<a href="https://www.nice.org.uk/about/what-we-do/our-programmes/nice-guidance/antimicrobial-prescribing-guidelines" class="text-red font-semibold underline">Summary of antimicrobial prescribing guidance for managing common infections summary tables</a>&nbsp; are available on the &nbsp<a href="https://www.bnf.org/news/2021/07/29/bnf-hosts-antimicrobial-summary-guidance-on-behalf-of-nice-and-phe/" class="text-red font-semibold underline">BNF website. </a>&nbsp;',
    category: 2,
    question_type: 2,
  },
  {
    id: 3,
    answers: [
      {
        id: 6,
        question: 3,
        answer: "Yes",
        is_text: false,
      },
      {
        id: 7,
        question: 3,
        answer: "No",
        is_text: false,
      },
      {
        id: 104,
        question: 3,
        answer: "Don’t know",
        is_text: false,
      },
      {
        id: 105,
        question: 3,
        answer: "Not Applicable",
        is_text: false,
      },
    ],
    type: "Option",
    name: "GP practice",
    question:
      "Do you analyse and discuss antibiotic prescribing at your practice in comparison to local indicators at least once a year?",
    description:
      'Analysing antibiotic prescribing figures against set indicators during the audit process enables the surgery to benchmark itself and determine whether there is a need to review their antibiotic prescribing practices. It is good practice to keep these figures and re-audit annually. &nbsp<a href="https://www.gov.uk/government/publications/uk-5-year-action-plan-for-antimicrobial-resistance-2019-to-2024" class="text-red font-semibold underline">Tackling antimicrobial resistance 2019–2024 - the UK’s five-year national action plan</a>&nbsp; aims to halve healthcare associated gram-negative bloodstream infections by 2024 and reduce the number of specific drug-resistant infections in people by 10% by 2025. Use national prescribing data on &nbsp<a href="https://fingertips.phe.org.uk/" class="text-red font-semibold underline">Fingertips</a>&nbsp; to compare antibiotic prescribing in your practice to local levels.',
    category: 2,
    question_type: 2,
  },
  {
    id: 7,
    answers: [
      {
        id: 14,
        question: 7,
        answer: "Yes",
        is_text: false,
      },
      {
        id: 15,
        question: 7,
        answer: "No",
        is_text: false,
      },
    ],
    type: "Both",
    name: "GP practice",
    question:
      "Do you use patient-focused strategies to highlight the importance of responsible antibiotic use?",
    description:
      'There are &nbsp<a href="http://www.rcgp.org.uk/TARGETantibiotics/" class="text-red font-semibold underline">patient facing materials</a>&nbsp; available in the TARGET toolkit that can be used in the surgery waiting areas or in the consultation to improve patient awareness and to facilitate communication around responsible antibiotic use, antibiotic resistance and patient self-care of infections. You can refer to posters and videos during your consultations to highlight that there is a national strategy to tackle resistance and inappropriate antibiotic use.',
    category: 2,
    question_type: 4,
  },
  {
    id: 11,
    answers: [
      {
        id: 19,
        question: 11,
        answer: null,
        is_text: true,
      },
    ],
    type: "Text",
    name: "GP practice",
    question:
      "Reflective notes on current guidance, benchmarking and patient focused strategies in my practice",
    description: "",
    category: 2,
    question_type: 3,
  },
  {
    id: 12,
    answers: [
      {
        id: 20,
        question: 12,
        answer: "Yes in last year",
        is_text: false,
      },
      {
        id: 21,
        question: 12,
        answer: "Yes in last 2 years",
        is_text: false,
      },
      {
        id: 22,
        question: 12,
        answer: "No",
        is_text: false,
      },
      {
        id: 106,
        question: 12,
        answer: "Not applicable",
        is_text: false,
      },
    ],
    type: "Option",
    name: "GP practice",
    question:
      "Have you been involved in a practice antibiotic audit in the last two years?",
    description:
      'It is important to conduct regular antibiotic audits within your practice, with peer review of the results to further improve antibiotic prescribing. Audits have been shown to lead to improved prescribing, and are now a requirement of your CPD and appraisal.  Why not identify an area in your practice where prescribing rates are high – e.g. sore throat or UTI and take one forward using the audit templates available in the &nbsp<a href="https://elearning.rcgp.org.uk/course/view.php?id=553" class="text-red font-semibold underline">TARGET Toolkit</a>&nbsp; .',
    category: 2,
    question_type: 2,
  },
  {
    id: 13,
    answers: [
      {
        id: 23,
        question: 13,
        answer: "Routinely by practice audit",
        is_text: false,
      },
      {
        id: 24,
        question: 13,
        answer: "Routinely at medication review",
        is_text: false,
      },
      {
        id: 107,
        question: 13,
        answer: "In response to MHRA Safety alerts",
        is_text: false,
      },
      {
        id: 108,
        question: 13,
        answer: "Ad-hoc if encountered",
        is_text: false,
      },
      {
        id: 109,
        question: 13,
        answer: "No set review",
        is_text: false,
      },
    ],
    type: "Option",
    name: "GP practice",
    question:
      "Do you, or your practice, carry out long-term/repeat antibiotic use reviews?",
    description:
      "Using appropriate Read Codes during consultations will further improve the audit process and can be used to support the prescribing decisions made on any given occasion. Consider having agreed codes or a code formulary in your practice and discuss at a meeting. Not recording an indication for prescribing antibiotics may be perceived as a reason to hide inappropriate prescribing. Agreeing your coding means that audits will be more meaningful.",
    category: 2,
    question_type: 2,
  },
  {
    id: 14,
    answers: [
      {
        id: 25,
        question: 14,
        answer: null,
        is_text: true,
      },
    ],
    type: "Text",
    name: "GP practice",
    question: "Reflective notes on audits and coding?",
    description: "",
    category: 2,
    question_type: 3,
  },
  {
    id: 15,
    answers: [
      {
        id: 26,
        question: 15,
        answer: "Yes",
        is_text: false,
      },
      {
        id: 27,
        question: 15,
        answer: "No",
        is_text: false,
      },
      {
        id: 28,
        question: 15,
        answer: "Don’t Know",
        is_text: false,
      },
    ],
    type: "Option",
    name: "GP practice",
    question:
      "Is the latest antibiotic guidance made available to all temporary prescribers working in your surgery?",
    description:
      "Taking a consistent approach within the surgery is very important to ensure patients receive the same consistent treatment",
    category: 2,
    question_type: 2,
  },
  {
    id: 16,
    answers: [
      {
        id: 29,
        question: 16,
        answer: "Weekly",
        is_text: false,
      },
      {
        id: 30,
        question: 16,
        answer: "Monthly",
        is_text: false,
      },
      {
        id: 31,
        question: 16,
        answer: "Yearly",
        is_text: false,
      },
      {
        id: 32,
        question: 16,
        answer: "No",
        is_text: false,
      },
      {
        id: 33,
        question: 16,
        answer: "I don’t agree with back-up prescriptions",
        is_text: false,
      },
    ],
    type: "Option",
    name: "GP practice",
    question:
      "Do you use back-up antibiotic prescriptions (sometimes referred to as delayed or deferred prescriptions) at least:",
    description:
      'Back up/delayed prescribing is a very useful strategy to use when pressure to prescribe is greater, especially just before the weekend. Typically, about 4/10 patients given a back-up/delayed prescription will collect and use the antibiotic. Patients may feel reassured that they have a prescription available to use if their symptoms do not get better as expected, or worsen, and the TARGET &nbsp<a href="https://elearning.rcgp.org.uk/mod/book/view.php?id=12647" class="text-red font-semibold underline">‘Treating your infection’ leaflets</a>&nbsp; can be used to help communicate the benefits of this approach.  Back-up/delayed prescribing is a specific technique which includes giving advice on the natural history of the illness, alarm symptoms, when to re-consult and why and when to take the antibiotics. For more information on this approach consider &nbsp<a href="https://phecloud-my.sharepoint.com/personal/eirwen_sides_ukhsa_gov_uk/Documents/Microsoft%20Teams%20Chat%20Files/Discussing%20antibiotics%20with%20patients:%20Discussing%20back-up/delayed%20antibiotic%20prescriptions%20(rcgp.org.uk)" class="text-red font-semibold underline">discussing antibiotics with patients</a>&nbsp;. To encourage use of back pocket/delayed prescriptions in your practice, computer prompts could be used when someone enters a Read Code for an infection. Using the SNOMED Read Code 967191000000104 when you give advice on treating your infection with self-care patient leaflet with back-up/delayed prescription issue will also help you audit prescribing decisions.',
    category: 2,
    question_type: 2,
  },
  {
    id: 17,
    answers: [
      {
        id: 34,
        question: 17,
        answer: "No",
        is_text: false,
      },
      {
        id: 35,
        question: 17,
        answer: "Yes – hard copies on my desk",
        is_text: false,
      },
      {
        id: 36,
        question: 17,
        answer: "Yes – digital copies on my computer or system",
        is_text: false,
      },
      {
        id: 37,
        question: 17,
        answer:
          "Yes – leaflet url links available in SMS templates/e-mail systems",
        is_text: false,
      },
      {
        id: 112,
        question: 17,
        answer: "Other",
        is_text: false,
      },
    ],
    type: "Option",
    name: "GP practice",
    question:
      "Do you have patient information leaflets easily available to share in the consultation?",
    description:
      'We are all familiar with the patient centred consultation, and a recent UK survey showed that 88% of patients trust their GP to give them advice on when they need antibiotics. Improved communication with patients around antibiotics has been shown to reduce antibiotic prescribing. The TARGET ‘&nbsp<a href="https://elearning.rcgp.org.uk/mod/book/view.php?id=12647&chapterid=444" class="text-red font-semibold underline">‘Treating your infection’</a>&nbsp; RTI and UTI leaflets have been produced to share with patients in consultations when you think they may benefit from a no or delayed prescription, these have been endorsed by NICE. These can be downloaded and used on many GP computer systems to form part of your patient consultation record. The leaflet allows you to close the consultation without a prescription, promote and increase patients’ confidence to self care and reduce re-consultations for the infection in the future. The ‘&nbsp<a href="https://elearning.rcgp.org.uk/mod/book/view.php?id=12647&chapterid=446" class="text-red font-semibold underline">When Should I Worry</a>&nbsp;’ booklet can be used for parents when you think their child will benefit from a no, or delayed antibiotic prescription. This should be endorsed in the consultation and related to the current illness. Record the use of a leaflet in consultations with Read Code 8CE',
    category: 2,
    question_type: 2,
  },
  {
    id: 18,
    answers: [
      {
        id: 38,
        question: 18,
        answer: null,
        is_text: true,
      },
    ],
    type: "Text",
    name: "GP practice",
    question: "Reflective Notes",
    description: "",
    category: 2,
    question_type: 3,
  },
  {
    id: 19,
    answers: [
      {
        id: 39,
        question: 19,
        answer: "Yes",
        is_text: false,
      },
      {
        id: 40,
        question: 19,
        answer: "No",
        is_text: false,
      },
      {
        id: 41,
        question: 19,
        answer: "Don’t Know",
        is_text: false,
      },
      {
        id: 113,
        question: 19,
        answer: "Not applicable",
        is_text: false,
      },
    ],
    type: "Option",
    name: "GP practice",
    question:
      "Do you keep a written record and surgery action plan resulting from antibiotic audits?",
    description:
      "Audits are most effective when actions are set to improve prescribing, with subsequent audits then being completed to analyse whether the actions have been successfully implemented. See where you sit within the Quality Premium for antibiotic prescribing in total, broad spectrum and UTI’s.",
    category: 2,
    question_type: 2,
  },
  {
    id: 20,
    answers: [
      {
        id: 42,
        question: 20,
        answer: "Yes",
        is_text: false,
      },
      {
        id: 43,
        question: 20,
        answer: "No",
        is_text: false,
      },
      {
        id: 114,
        question: 20,
        answer: "Don’t know",
        is_text: false,
      },
    ],
    type: "Option",
    name: "GP practice",
    question:
      "Do you have strategy to avoid patients re-consulting with other clinicians to obtain antibiotics?",
    description:
      "Patients with a high expectation for antibiotics may revisit their surgery or other providers to obtain a prescription for antibiotics if they were initially refused antibiotics. This may be countered by good communication skills, the use of leaflets and a back-up/delayed prescription strategy as part of a standardised approach supported by antibiotic guidance. We suggest you create an action plan at a practice meeting with all staff, and ask your local medicines manager to help.",
    category: 2,
    question_type: 2,
  },
  {
    id: 21,
    answers: [
      {
        id: 44,
        question: 21,
        answer: "Yes, in the last year",
        is_text: false,
      },
      {
        id: 45,
        question: 21,
        answer: "Yes, in the last two years",
        is_text: false,
      },
      {
        id: 115,
        question: 21,
        answer: "Yes, more than three years ago",
        is_text: false,
      },
      {
        id: 116,
        question: 21,
        answer: "No",
        is_text: false,
      },
    ],
    type: "Option",
    name: "GP practice",
    question:
      "Have you undertaken any learning related to antimicrobial stewardship",
    description:
      "These courses count as Continued Professional Development (CPD) for your portfolio. Training resources are free for all to access from the RCGP website.",
    category: 2,
    question_type: 2,
  },
  {
    id: 22,
    answers: [
      {
        id: 46,
        question: 22,
        answer: "Yes",
        is_text: false,
      },
      {
        id: 47,
        question: 22,
        answer: "No",
        is_text: false,
      },
      {
        id: 117,
        question: 22,
        answer: "Don’t know",
        is_text: false,
      },
    ],
    type: "Option",
    name: "GP practice",
    question:
      "Is there a GP or nurse prescriber within your practice who takes a lead for antibiotic stewardship in the practice?",
    description:
      "Having an antibiotic champion within the surgery can lead to significant improvements in antibiotic prescribing as they can help to drive and maintain initiatives to affect the required changes. The champion should aim to discuss antibiotic issues in the practice at least twice a year.",
    category: 2,
    question_type: 2,
  },
  {
    id: 23,
    answers: [
      {
        id: 48,
        question: 23,
        answer: null,
        is_text: true,
      },
    ],
    type: "Text",
    name: "GP practice",
    question:
      "Reflective notes, for example which AMS learning you have competed, how you found this, changes to current practice that you might consider, etc.",
    description: "",
    category: 2,
    question_type: 3,
  },
  {
    id: 44,
    answers: [
      {
        id: 110,
        question: 44,
        answer: "Yes",
        is_text: false,
      },
      {
        id: 111,
        question: 44,
        answer: "No",
        is_text: false,
      },
    ],
    type: "Option",
    name: "GP practice",
    question:
      "Do you record clinical indications for antibiotics prescribed in patient notes using digital Read Codes or Snomed?",
    description:
      "Using the right digital clinical codes during consultations will improve continuity of patient care and facilitate the audit process and can be used to support prescribing decisions. Standardising coding means that audits will be more accurate across the practice. Consider identifying agreed digital codes or a code formulary in your practice and discuss at a meeting. Not recording an indication for prescribing antibiotics may be interpreted as an absence of evidence of infection.",
    category: 2,
    question_type: 2,
  },
];
export const ccg_organization = [
  {
    id: 4,
    answers: [
      {
        id: 8,
        question: 4,
        answer: "Yes",
        is_text: false,
      },
      {
        id: 9,
        question: 4,
        answer: "No",
        is_text: false,
      },
    ],
    type: "Option",
    name: "CCG Organization",
    question:
      "Are there written aims and objectives for assuring the quality of antibiotic use?",
    description: "",
    category: 3,
    question_type: 2,
  },
  {
    id: 5,
    answers: [
      {
        id: 10,
        question: 5,
        answer: "Yes",
        is_text: false,
      },
      {
        id: 11,
        question: 5,
        answer: "No",
        is_text: false,
      },
    ],
    type: "Option",
    name: "CCG Organization",
    question:
      "Has the antibiotic prescribing advisory group had an appraisal within the last year covering education plans for management?",
    description: "",
    category: 3,
    question_type: 2,
  },
  {
    id: 6,
    answers: [
      {
        id: 12,
        question: 6,
        answer: "Yes",
        is_text: false,
      },
      {
        id: 13,
        question: 6,
        answer: "No",
        is_text: false,
      },
    ],
    type: "Option",
    name: "CCG Organization",
    question:
      "Is there an existing Outpatient Antimicrobial Therapy (OPAT) service?",
    description: "",
    category: 3,
    question_type: 2,
  },
  {
    id: 24,
    answers: [
      {
        id: 49,
        question: 24,
        answer: "Yes",
        is_text: false,
      },
      {
        id: 50,
        question: 24,
        answer: "No",
        is_text: false,
      },
    ],
    type: "Option",
    name: "CCG Organization",
    question:
      "Is an antibiotic committee or equivalent accountable to the Infection Control Drugs and Therapeutics Committee with an annual action list?",
    description: "",
    category: 3,
    question_type: 2,
  },
  {
    id: 25,
    answers: [
      {
        id: 51,
        question: 25,
        answer: "Yes",
        is_text: false,
      },
      {
        id: 52,
        question: 25,
        answer: "No",
        is_text: false,
      },
    ],
    type: "Option",
    name: "CCG Organization",
    question:
      "Do the non executive directors receive an annual report pertaining to antibiotic stewardship?",
    description: "",
    category: 3,
    question_type: 2,
  },
  {
    id: 26,
    answers: [
      {
        id: 53,
        question: 26,
        answer: "Yes",
        is_text: false,
      },
      {
        id: 54,
        question: 26,
        answer: "No",
        is_text: false,
      },
    ],
    type: "Option",
    name: "CCG Organization",
    question:
      "Is there an antibiotic prescribing advisor post in place to complete antibiotic duties in primary care?",
    description: "",
    category: 3,
    question_type: 2,
  },
  {
    id: 27,
    answers: [
      {
        id: 55,
        question: 27,
        answer: "Yes",
        is_text: false,
      },
      {
        id: 56,
        question: 27,
        answer: "No",
        is_text: false,
      },
    ],
    type: "Option",
    name: "CCG Organization",
    question:
      "Does the lead antibiotic prescribing advisor have ongoing training in infection management and antibiotic use?",
    description: "",
    category: 3,
    question_type: 2,
  },
];
export const local_pharmacy = [
  {
    id: 28,
    answers: [
      {
        id: 57,
        question: 28,
        answer: "Yes",
        is_text: false,
      },
      {
        id: 58,
        question: 28,
        answer: "No",
        is_text: false,
      },
    ],
    type: "Option",
    name: "Local Pharmacy",
    question:
      "Do you use antimicrobial stewardship tools when clinically checking prescriptions for antibiotics for common infections?",
    description:
      'Pharmacists may also clinically check prescriptions for antibiotics prior to dispensing, using national and/or local antibiotic prescribing or antimicrobial stewardship guidance. Checking course duration is correct according to BNF and local prescribing guidance/formulary is important. A collection of national guidance resources for community pharmacy teams that provides information on antibiotics for common infections can be found on the &nbsp<a href="https://elearning.rcgp.org.uk/mod/book/view.php?id=14887" class="text-red font-semibold underline">TARGET antibiotics toolkit.</a>&nbsp; \r\nThe &nbsp<a href="https://elearning.rcgp.org.uk/mod/book/view.php?id=13511&chapterid=784" class="text-red font-semibold underline">TARGET Antibiotic checklist</a>&nbsp; is a resource for community pharmacy staff, designed to be used with patients or carers who are collecting antibiotics. It includes a list of the common checks community pharmacy team complete for antibiotics.\r\nThe &nbsp<a href="https://elearning.rcgp.org.uk/mod/book/view.php?id=13511&chapterid=785" class="text-red font-semibold underline">Pharmacy counselling sheet</a>&nbsp; is a supportive resource to the TARGET Antibiotic Checklist and summarises information around antibiotics which can be of further help when screening/completing checks.',
    category: 4,
    question_type: 2,
  },
  {
    id: 29,
    answers: [
      {
        id: 59,
        question: 29,
        answer: "Yes and review/update once a year",
        is_text: false,
      },
      {
        id: 60,
        question: 29,
        answer: "Yes but don’t review/update annually",
        is_text: false,
      },
      {
        id: 61,
        question: 29,
        answer: "No",
        is_text: false,
      },
      {
        id: 62,
        question: 29,
        answer: "Don’t know",
        is_text: false,
      },
    ],
    type: "Option",
    name: "Local Pharmacy",
    question:
      "Do you have an action plan for antimicrobial stewardship in your community pharmacy?",
    description:
      '&nbsp<a href="https://www.gov.uk/government/publications/uk-5-year-action-plan-for-antimicrobial-resistance-2024-to-2029" class="text-red font-semibold underline">UK 5-year action plan for antimicrobial resistance 2024 to 2029</a>&nbsp; aims to reduce the number of antibiotics used by humans by 5% by 2029 and keep levels of drug resistant infections in humans below levels documented in 2019/20. All members of the community pharmacy team play an important role in antimicrobial stewardship. \r\nA localised action plan may be helpful in planning how the team members work together to enable a common approach. Action plans should be reviewed regularly to\r\nensure they still fit for purpose/ relevant and practical to implement by community pharmacy teams.\r\nRemember that your most recent AMS action plan needs to be available to all staff working in your pharmacy. Taking a consistent approach within the team is very important, otherwise it gives patients mixed messages and they may re-consult with another colleague.\r\nStructured messages from local community pharmacies taking part in &nbsp<a href="https://www.england.nhs.uk/primary-care/pharmacy/pharmacy-services/pharmacy-first/" class="text-red font-semibold underline">Pharmacy First</a>&nbsp;should be promptly sent to the patient’s general practice and a consistent approach to antibiotic supply adopted where possible, to discourage inappropriate antibiotic-seeking behaviour.',
    category: 4,
    question_type: 2,
  },
  {
    id: 30,
    answers: [
      {
        id: 63,
        question: 30,
        answer: "Yes",
        is_text: false,
      },
      {
        id: 64,
        question: 30,
        answer: "No",
        is_text: false,
      },
      {
        id: 65,
        question: 30,
        answer: "Not applicable as we don’t use PGDs",
        is_text: false,
      },
    ],
    type: "Option",
    name: "Local Pharmacy",
    question:
      "Does your action plan include a strategy to avoid patients re-consulting with other clinicians to obtain antibiotics?",
    description:
      "Patients with a high expectation for antibiotics may revisit their surgery or other providers (other pharmacies, out of hours etc…) to obtain a prescription for antibiotics if they were initially refused antibiotics. \r\nThis may be countered by good communication skills, the use of leaflets and a “return for re-assessment strategy” as part of a standardised approach supported by antibiotic guidance. \r\nWe suggest you include the use of such resources as part of an action plan at a meeting with all staff. It may be helpful to check the patient’s medical records to identify antibiotic-seeking behaviour and avoid undermining no-prescribing decisions by other health professionals (including other community pharmacists).",
    category: 4,
    question_type: 2,
  },
  {
    id: 31,
    answers: [
      {
        id: 66,
        question: 31,
        answer: "Yes",
        is_text: false,
      },
      {
        id: 67,
        question: 31,
        answer: "No",
        is_text: false,
      },
    ],
    type: "Option",
    name: "Local Pharmacy",
    question:
      "Is there a member of your pharmacy team who takes a lead or is a champion for antimicrobial stewardship in the pharmacy?",
    description:
      "Having a champion within the pharmacy can lead to significant improvements in antimicrobial stewardship as they can help to co-ordinate, drive and maintain initiatives to affect the required changes. The champion should aim to discuss antibiotic issues e.g patients consultations that went well; effective methods to tackle inappropriate antibiotics requests, dispensing data; updates in the action plan etc  with the team regularly but at least twice a year.",
    category: 4,
    question_type: 2,
  },
  {
    id: 32,
    answers: [
      {
        id: 68,
        question: 32,
        answer: "Yes, within the last year",
        is_text: false,
      },
      {
        id: 69,
        question: 32,
        answer: "Yes, within the last two years",
        is_text: false,
      },
      {
        id: 70,
        question: 32,
        answer: "No",
        is_text: false,
      },
    ],
    type: "Option",
    name: "Local Pharmacy",
    question:
      "Have you completed training/learning opportunities on antimicrobial stewardship within the last year?",
    description:
      'Community pharmacy teams can access training and resources to improve their understanding of antimicrobial stewardship and keep up-to-date with the latest evidence. \r\nThe online course &nbsp<a href="https://portal.e-lfh.org.uk/Component/Details/602874" class="text-red font-semibold underline">Antimicrobial Stewardship for Community Pharmacy (e-Learning)’</a>&nbsp; provides information on antimicrobial resistance (AMR) and emphasises the role community pharmacy staff play. \r\nThe TARGET Antibiotics toolkit has a series of clinical webinars developed with Royal College of General Practitioners and an online FutureLearn course with the British Association for Antimicrobial Chemotherapy which may also be relevant to Pharmacy teams.\r\nThe NHS Specialist Pharmacy Service hosts useful “explainer videos” and information on the use and auditing of PGDs.',
    category: 4,
    question_type: 2,
  },
  {
    id: 33,
    answers: [
      {
        id: 71,
        question: 33,
        answer: "Yes",
        is_text: false,
      },
      {
        id: 72,
        question: 33,
        answer: "No",
        is_text: false,
      },
      {
        id: 73,
        question: 33,
        answer: "Don’t know",
        is_text: false,
      },
      {
        id: 74,
        question: 33,
        answer: "Not applicable",
        is_text: false,
      },
    ],
    type: "Option",
    name: "Local Pharmacy",
    question:
      "If you are participating in pharmacy first, have you completed the relevant self-assessment framework to your role?",
    description:
      'In England, &nbsp<a href="https://www.cppe.ac.uk/services/pharmacy-first/" class="text-red font-semibold underline">CPPE has a range of learning resources</a>&nbsp; to prepare and support pharmacy professionals to provide the NHS Pharmacy First service. These resources include a self-assessment framework developed in partnership with NHS England, which supports you to reflect on your knowledge, skills and behaviours that are essential to provide all three elements of the NHS Pharmacy First service. Through the self-assessment, you can identify any gaps and make an action plan to develop as required. \r\nIf suggested as part of action planning, pharmacists can undertake or update clinical skills training provided by &nbsp<a href="https://www.cliniskills.com/community-pharmacists/" class="text-red font-semibold underline">CliniSkills</a>&nbsp;.',
    category: 4,
    question_type: 2,
  },
  {
    id: 34,
    answers: [
      {
        id: 75,
        question: 34,
        answer: null,
        is_text: true,
      },
    ],
    type: "Text",
    name: "Local Pharmacy",
    question:
      "Reflective notes on current guidance, training and action plan strategies in my pharmacy",
    description: "",
    category: 4,
    question_type: 3,
  },
  {
    id: 35,
    answers: [
      {
        id: 76,
        question: 35,
        answer: "No",
        is_text: false,
      },
      {
        id: 77,
        question: 35,
        answer: "Yes – hard copies",
        is_text: false,
      },
      {
        id: 78,
        question: 35,
        answer: "Yes – online",
        is_text: false,
      },
      {
        id: 79,
        question: 35,
        answer: "Other (allow free text response)",
        is_text: false,
      },
    ],
    type: "Option",
    name: "Local Pharmacy",
    question:
      "Do you have self-care leaflets easily available to share with patients when an antibiotic is not required for their symptoms?",
    description:
      'We are all familiar with the patient centred consultation, and a recent UK survey showed that 87% of patients trust their GPs to give them advice on when they need antibiotics. Improved communication with patients around antibiotics has been shown to reduce demand for antibiotics. The TARGET ‘&nbsp<a href="https://elearning.rcgp.org.uk/mod/book/view.php?id=12647" class="text-red font-semibold underline">Treating your infection</a>&nbsp;’ RTI and UTI leaflets have been produced to share with patients in consultations where they may benefit from a watch-and-wait strategy, including symptom management and self-care. These can be downloaded and printed or shared with patients via a website link. \r\n\r\nThe ‘&nbsp<a href="https://www.bristol.ac.uk/media-library/sites/primaryhealthcare/documents/target/caring-for-children-with-cough-A4-leaflet.pdf" class="text-red font-semibold underline">caring for children with coughs</a>&nbsp;’ leaflet developed by University of Bristol can be a helpful leaflet to share with parents of children with coughs (not due to asthma).',
    category: 4,
    question_type: 2,
  },
  {
    id: 36,
    answers: [
      {
        id: 80,
        question: 36,
        answer: "Yes",
        is_text: false,
      },
      {
        id: 81,
        question: 36,
        answer: "No",
        is_text: false,
      },
    ],
    type: "Option",
    name: "Local Pharmacy",
    question:
      "Do you use resources or aids to support you when having conversations with patients with regards to antibiotic use and resistance?",
    description:
      'The &nbsp<a href="https://elearning.rcgp.org.uk/mod/book/view.php?id=13511&chapterid=784" class="text-red font-semibold underline">TARGET Antibiotic checklist</a>&nbsp; is a resource for community pharmacy staff, designed to be used with patients or carers who are collecting antibiotics. It aims to facilitate individualised advice to patients regarding antibiotic use. By completing the checklist, both patients and pharmacists can ensure that the patient receives appropriate guidance.\r\n\r\nThe &nbsp<a href="https://elearning.rcgp.org.uk/mod/book/view.php?id=13511&chapterid=785" class="text-red font-semibold underline">Pharmacy counselling sheet</a>&nbsp; is a supportive resource to the TARGET Antibiotic Checklist and summarises information around antibiotics.\r\n\r\nThe TARGET ‘&nbsp<a href="https://elearning.rcgp.org.uk/mod/book/view.php?id=12647" class="text-red font-semibold underline">Treating your infection</a>&nbsp;’ leaflets and the &nbsp<a href="https://www.bristol.ac.uk/media-library/sites/primaryhealthcare/documents/target/caring-for-children-with-cough-A4-leaflet.pdf" class="text-red font-semibold underline">caring for children with coughs’ leaflet</a>&nbsp; developed by University of Bristol can also be a helpful resources to use in discussion with patients when they are not supplied an antibiotic. \r\n\r\nTARGET also has a series of learning resources and helpful tools for clinicians when &nbsp<a href="https://elearning.rcgp.org.uk/mod/book/view.php?id=12646" class="text-red font-semibold underline">discussing antibiotics with patients</a>&nbsp;.',
    category: 4,
    question_type: 2,
  },
  {
    id: 37,
    answers: [
      {
        id: 82,
        question: 37,
        answer: "Yes",
        is_text: false,
      },
      {
        id: 83,
        question: 37,
        answer: "If yes - which ones (free text)",
        is_text: false,
      },
      {
        id: 84,
        question: 37,
        answer: "No",
        is_text: false,
      },
    ],
    type: "Option",
    name: "Local Pharmacy",
    question:
      "Do you use patient-focused strategies to highlight the importance of responsible antibiotic use in your pharmacy?",
    description:
      'There are &nbsp<a href="https://elearning.rcgp.org.uk/mod/book/view.php?id=12649&chapterid=487" class="text-red font-semibold underline">patient facing materials</a>&nbsp; available in the TARGET toolkit that can be used in the community pharmacy or during a consultation to improve patient awareness and to facilitate communication around responsible antibiotic use, antibiotic resistance and patient self-care of infections. \r\nYou can refer to posters, leaflets and videos during your consultations to highlight that there is a national strategy to tackle resistance and inappropriate antibiotic use.',
    category: 4,
    question_type: 2,
  },
  {
    id: 38,
    answers: [
      {
        id: 85,
        question: 38,
        answer: null,
        is_text: true,
      },
    ],
    type: "Text",
    name: "Local Pharmacy",
    question:
      "Reflective Notes – discussing antibiotic use, self-care and safety netting with patients",
    description: "",
    category: 4,
    question_type: 3,
  },
  {
    id: 39,
    answers: [
      {
        id: 86,
        question: 39,
        answer: "Yes – using PGDs",
        is_text: false,
      },
      {
        id: 87,
        question: 39,
        answer: "No but some of our other pharmacy staff do",
        is_text: false,
      },
      {
        id: 88,
        question: 39,
        answer: "No and our pharmacy does not yet use these PGDs",
        is_text: false,
      },
      {
        id: 89,
        question: 39,
        answer: "Other (free text)",
        is_text: false,
      },
    ],
    type: "Option",
    name: "Local Pharmacy",
    question:
      "Do you use Patient Group Directions when supplying antibiotics for common infections like acute sore throat and urinary tract infections?",
    description:
      'Community pharmacists can use Patient Group Directions (PGD) for clinical services for supply of antibiotics. These PGDs are based on guidance produced by agencies like &nbsp<a href="https://www.nice.org.uk/guidance/health-protection/communicable-diseases/antimicrobial-stewardship" class="text-red font-semibold underline">National Institute for Clinical Excellence (NICE)</a>&nbsp;.',
    category: 4,
    question_type: 2,
  },
  {
    id: 40,
    answers: [
      {
        id: 90,
        question: 40,
        answer: "Yes",
        is_text: false,
      },
      {
        id: 91,
        question: 40,
        answer: "No",
        is_text: false,
      },
      {
        id: 92,
        question: 40,
        answer: "Not applicable as we do not use PGDs",
        is_text: false,
      },
      {
        id: 93,
        question: 40,
        answer:
          "Not applicable as we do not have this type of data available yet",
        is_text: false,
      },
      {
        id: 94,
        question: 40,
        answer: "Other",
        is_text: false,
      },
    ],
    type: "Option",
    name: "Local Pharmacy",
    question:
      "If applicable, does your pharmacy review data from supply of antibiotics under PGDs and compare practice with peers?",
    description:
      "If your pharmacy supplies antibiotics via PGD (e.g. under Pharmacy First service) there may be an opportunity to review and benchmark with peers (e.g. compare proportion of patients by clinical condition supplied antibiotics under the PGD). Contact your commissioner to find out more.",
    category: 4,
    question_type: 2,
  },
  {
    id: 41,
    answers: [
      {
        id: 95,
        question: 41,
        answer: "Yes",
        is_text: false,
      },
      {
        id: 96,
        question: 41,
        answer: "No",
        is_text: false,
      },
      {
        id: 97,
        question: 41,
        answer: "Don’t know",
        is_text: false,
      },
      {
        id: 98,
        question: 41,
        answer: "Not applicable as we do not use these PGDs",
        is_text: false,
      },
      {
        id: 99,
        question: 41,
        answer: "Other",
        is_text: false,
      },
    ],
    type: "Option",
    name: "Local Pharmacy",
    question:
      "If applicable, has your pharmacy been involved in an antibiotic PGD audit in the last two years?",
    description:
      'PGDs use should be audited as part of an organisation’s medical audit programme. \r\n&nbsp<a href="https://www.nice.org.uk/guidance/mpg2" class="text-red font-semibold underline">NICE PGD Guidance</a>&nbsp; states that service providers should ‘Agree and undertake a planned programme of monitoring and evaluation of PGD use within the service.’ \r\nAudits have been shown to lead to improve how antimicrobials are prescribed and provided to patients. \r\nWhy not identify an area in your practice where proportion of patients supplied antibiotics or antimicrobial stewardship practice can be improved and audit using the NHS &nbsp<a href="https://www.sps.nhs.uk/wp-content/uploads/2020/09/SPS-PGD-Audit-Tool.docx" class="text-red font-semibold underline">Specialist Pharmacy Service</a>&nbsp; audit tool.  \r\nYour commissioning and provider organisation will also be able to provide you with help to assess the use of PGDs in your practice.',
    category: 4,
    question_type: 2,
  },
  {
    id: 42,
    answers: [
      {
        id: 100,
        question: 42,
        answer: "Yes",
        is_text: false,
      },
      {
        id: 101,
        question: 42,
        answer: "No",
        is_text: false,
      },
      {
        id: 102,
        question: 42,
        answer: "Not applicable (we do not use PGDs to supply antibiotics)",
        is_text: false,
      },
    ],
    type: "Option",
    name: "Local Pharmacy",
    question:
      "If your pharmacy is supplying antibiotics via PGD, do you supply self-care and safety netting advice to patients who are not given an antibiotic?",
    description:
      "Back-up/delayed prescribing is a specific technique which includes giving advice on the natural history of the illness, alarm symptoms, when to re-consult and why and when to take the antibiotics. It is a useful strategy typically used in general practice when pressure to prescribe is greater, especially just before the weekend. \r\nTypically, about 4/10 patients given a back-up/delayed prescription will collect and use the antibiotic. Patients may feel reassured that they have a prescription available to use if their symptoms do not get better as expected or worsen. \r\nFor pharmacies supplying antibiotics by PGD the equivalent approach would be to provide self-care and safety netting advice to patients who are not given an antibiotic and invite the patient to return to the pharmacy if symptoms do not improve. This is sometimes called a watch-and-wait strategy.",
    category: 4,
    question_type: 2,
  },
  {
    id: 43,
    answers: [
      {
        id: 103,
        question: 43,
        answer: null,
        is_text: true,
      },
    ],
    type: "Text",
    name: "Local Pharmacy",
    question: "Reflective notes around strategies for supplying antibiotics",
    description: "",
    category: 4,
    question_type: 3,
  },
];
export const practices = [
  {
    id: 1,
    name: "General practice surgery",
  },
  {
    id: 2,
    name: "Primary care network",
  },
  {
    id: 3,
    name: "Out of hours",
  },
  {
    id: 4,
    name: "Hospital",
  },
  {
    id: 5,
    name: "Other (free response)",
  },
];
export const roles = [
  {
    id: 1,
    name: "Community pharmacist",
  },
  {
    id: 2,
    name: "Foundation trainee",
  },
  {
    id: 3,
    name: "Pharmacist",
  },
];
export const regions = [
  {
    id: 2,
    name: "Scotland",
    region_boards: [
      {
        id: 13,
        name: "NHS Ayrshire & Arran",
      },
      {
        id: 14,
        name: "NHS Borders",
      },
      {
        id: 15,
        name: "NHS Dumfries & Galloway",
      },
      {
        id: 16,
        name: "NHS Fife",
      },
      {
        id: 17,
        name: "NHS Forth Valley",
      },
      {
        id: 18,
        name: "NHS Grampian",
      },
      {
        id: 19,
        name: "NHS Greater Glasgow & Clyde",
      },
      {
        id: 20,
        name: "NHS Highland",
      },
      {
        id: 21,
        name: "NHS Lanarkshire",
      },
    ],
  },
  {
    id: 4,
    name: "Wales",
    region_boards: [
      {
        id: 27,
        name: "Aneurin Bevan Health Board",
      },
      {
        id: 28,
        name: "Betsi Cadwaladr",
      },
      {
        id: 29,
        name: "University Health Board",
      },
      {
        id: 30,
        name: "Cardiff and Vale University Health Board",
      },
      {
        id: 31,
        name: "Cwm Taf Morgannwg University Health Board",
      },
      {
        id: 32,
        name: "Hywel Dda University Health Board",
      },
    ],
  },
  {
    id: 3,
    name: "Northern Ireland",
    region_boards: [
      {
        id: 22,
        name: "Belfast Health and Social Care Trust",
      },
      {
        id: 23,
        name: "Northern Health and Social Care Trust",
      },
      {
        id: 24,
        name: "Northern Ireland Ambulance Service",
      },
      {
        id: 25,
        name: "HSC Trust",
      },
      {
        id: 26,
        name: "South Eastern Health and Social Care Trust",
      },
    ],
  },
  {
    id: 6,
    name: "England",
    region_boards: [
      {
        id: 9,
        name: "Humber and North Yorkshire",
      },
      {
        id: 10,
        name: "North East and North Cumbria​",
      },
      {
        id: 11,
        name: "South Yorkshire",
      },
      {
        id: 12,
        name: "West Yorkshire",
      },
    ],
  },
];
export const deepCopy = (arr) => {
  let copy = [];
  arr.forEach((elem) => {
    if (Array.isArray(elem)) {
      copy.push(deepCopy(elem));
    } else {
      if (typeof elem === "object") {
        copy.push(deepCopyObject(elem));
      } else {
        copy.push(elem);
      }
    }
  });
  return copy;
};
// Helper function to deal with Objects
export const deepCopyObject = (obj) => {
  let tempObj = {};
  for (let [key, value] of Object.entries(obj)) {
    if (Array.isArray(value)) {
      tempObj[key] = deepCopy(value);
    } else {
      if (typeof value === "object" && value != null) {
        tempObj[key] = deepCopyObject(value);
      } else {
        tempObj[key] = value;
      }
    }
  }
  return tempObj;
};

export const dummyReportData = [
  {
    question_text:
      "If you are participating in pharmacy first, have you completed the relevant self-assessment framework to your role?",
    user_answer: "No",
    answers_count: [
      {
        name: "Don’t know",
        count: 0,
      },
      {
        name: "No",
        count: 1,
      },
      {
        name: "Not applicable",
        count: 0,
      },
      {
        name: "Yes",
        count: 0,
      },
    ],
    answers_count_region: [
      {
        name: "Don’t know",
        count: 0,
      },
      {
        name: "No",
        count: 1,
      },
      {
        name: "Not applicable",
        count: 0,
      },
      {
        name: "Yes",
        count: 0,
      },
    ],
    total_answers: 1,
    total_answers_region: 0,
  },
  {
    question_text:
      "Do you have self-care leaflets easily available to share with patients when an antibiotic is not required for their symptoms?",
    user_answer: "Yes – hard copies",
    answers_count: [
      {
        name: "No",
        count: 0,
      },
      {
        name: "Yes – online",
        count: 0,
      },
      {
        name: "Yes – hard copies",
        count: 1,
      },
      {
        name: "Other (allow free text response)",
        count: 0,
      },
    ],
    answers_count_region: [
      {
        name: "No",
        count: 0,
      },
      {
        name: "Yes – online",
        count: 0,
      },
      {
        name: "Yes – hard copies",
        count: 1,
      },
      {
        name: "Other (allow free text response)",
        count: 0,
      },
    ],
    total_answers: 1,
    total_answers_region: 0,
  },
  {
    question_text:
      "Do you use antimicrobial stewardship tools when clinically checking prescriptions for antibiotics for common infections?",
    user_answer: "Yes",
    answers_count: [
      {
        name: "No",
        count: 0,
      },
      {
        name: "Yes",
        count: 1,
      },
    ],
    answers_count_region: [
      {
        name: "No",
        count: 0,
      },
      {
        name: "Yes",
        count: 1,
      },
    ],
    total_answers: 1,
    total_answers_region: 0,
  },
  {
    question_text:
      "Do you have an action plan for antimicrobial stewardship in your community pharmacy?",
    user_answer: "Yes but don’t review/update annually",
    answers_count: [
      {
        name: "No",
        count: 0,
      },
      {
        name: "Don’t know",
        count: 0,
      },
      {
        name: "Yes and review/update once a year",
        count: 0,
      },
      {
        name: "Yes but don’t review/update annually",
        count: 1,
      },
    ],
    answers_count_region: [
      {
        name: "No",
        count: 0,
      },
      {
        name: "Don’t know",
        count: 0,
      },
      {
        name: "Yes and review/update once a year",
        count: 0,
      },
      {
        name: "Yes but don’t review/update annually",
        count: 1,
      },
    ],
    total_answers: 1,
    total_answers_region: 0,
  },
  {
    question_text:
      "Does your action plan include a strategy to avoid patients re-consulting with other clinicians to obtain antibiotics?",
    user_answer: "Not applicable as we don’t use PGDs",
    answers_count: [
      {
        name: "No",
        count: 0,
      },
      {
        name: "Not applicable as we don’t use PGDs",
        count: 1,
      },
      {
        name: "Yes",
        count: 0,
      },
    ],
    answers_count_region: [
      {
        name: "No",
        count: 0,
      },
      {
        name: "Not applicable as we don’t use PGDs",
        count: 1,
      },
      {
        name: "Yes",
        count: 0,
      },
    ],
    total_answers: 1,
    total_answers_region: 0,
  },
  {
    question_text:
      "Is there a member of your pharmacy team who takes a lead or is a champion for antimicrobial stewardship in the pharmacy?",
    user_answer: "Yes",
    answers_count: [
      {
        name: "No",
        count: 0,
      },
      {
        name: "Yes",
        count: 1,
      },
    ],
    answers_count_region: [
      {
        name: "No",
        count: 0,
      },
      {
        name: "Yes",
        count: 1,
      },
    ],
    total_answers: 1,
    total_answers_region: 0,
  },
  {
    question_text:
      "Have you completed training/learning opportunities on antimicrobial stewardship within the last year?",
    user_answer: "Yes, within the last two years",
    answers_count: [
      {
        name: "No",
        count: 0,
      },
      {
        name: "Yes, within the last two years",
        count: 1,
      },
      {
        name: "Yes, within the last year",
        count: 0,
      },
    ],
    answers_count_region: [
      {
        name: "No",
        count: 0,
      },
      {
        name: "Yes, within the last two years",
        count: 1,
      },
      {
        name: "Yes, within the last year",
        count: 0,
      },
    ],
    total_answers: 1,
    total_answers_region: 0,
  },
  {
    question_text:
      "Do you use resources or aids to support you when having conversations with patients with regards to antibiotic use and resistance?",
    user_answer: "No",
    answers_count: [
      {
        name: "No",
        count: 1,
      },
      {
        name: "Yes",
        count: 0,
      },
    ],
    answers_count_region: [
      {
        name: "No",
        count: 1,
      },
      {
        name: "Yes",
        count: 0,
      },
    ],
    total_answers: 1,
    total_answers_region: 0,
  },
  {
    question_text:
      "Do you use patient-focused strategies to highlight the importance of responsible antibiotic use in your pharmacy?",
    user_answer: "If yes - which ones (free text)",
    answers_count: [
      {
        name: "If yes - which ones (free text)",
        count: 1,
      },
      {
        name: "No",
        count: 0,
      },
      {
        name: "Yes",
        count: 0,
      },
    ],
    answers_count_region: [
      {
        name: "If yes - which ones (free text)",
        count: 1,
      },
      {
        name: "No",
        count: 0,
      },
      {
        name: "Yes",
        count: 0,
      },
    ],
    total_answers: 1,
    total_answers_region: 0,
  },
  {
    question_text:
      "Do you use Patient Group Directions when supplying antibiotics for common infections like acute sore throat and urinary tract infections?",
    user_answer: "No and our pharmacy does not yet use these PGDs",
    answers_count: [
      {
        name: "Other (free text)",
        count: 0,
      },
      {
        name: "No but some of our other pharmacy staff do",
        count: 0,
      },
      {
        name: "Yes – using PGDs",
        count: 0,
      },
      {
        name: "No and our pharmacy does not yet use these PGDs",
        count: 1,
      },
    ],
    answers_count_region: [
      {
        name: "Other (free text)",
        count: 0,
      },
      {
        name: "No but some of our other pharmacy staff do",
        count: 0,
      },
      {
        name: "Yes – using PGDs",
        count: 0,
      },
      {
        name: "No and our pharmacy does not yet use these PGDs",
        count: 1,
      },
    ],
    total_answers: 1,
    total_answers_region: 0,
  },
  {
    question_text:
      "If applicable, does your pharmacy review data from supply of antibiotics under PGDs and compare practice with peers?",
    user_answer: "Other",
    answers_count: [
      {
        name: "Yes",
        count: 0,
      },
      {
        name: "No",
        count: 0,
      },
      {
        name: "Other",
        count: 1,
      },
      {
        name: "Not applicable as we do not use PGDs",
        count: 0,
      },
      {
        name: "Not applicable as we do not have this type of data available yet",
        count: 0,
      },
    ],
    answers_count_region: [
      {
        name: "Yes",
        count: 0,
      },
      {
        name: "No",
        count: 0,
      },
      {
        name: "Other",
        count: 1,
      },
      {
        name: "Not applicable as we do not use PGDs",
        count: 0,
      },
      {
        name: "Not applicable as we do not have this type of data available yet",
        count: 0,
      },
    ],
    total_answers: 1,
    total_answers_region: 0,
  },
  {
    question_text:
      "If applicable, has your pharmacy been involved in an antibiotic PGD audit in the last two years?",
    user_answer: "Other",
    answers_count: [
      {
        name: "Yes",
        count: 0,
      },
      {
        name: "Don’t know",
        count: 0,
      },
      {
        name: "No",
        count: 0,
      },
      {
        name: "Other",
        count: 1,
      },
      {
        name: "Not applicable as we do not use these PGDs",
        count: 0,
      },
    ],
    answers_count_region: [
      {
        name: "Yes",
        count: 0,
      },
      {
        name: "Don’t know",
        count: 0,
      },
      {
        name: "No",
        count: 0,
      },
      {
        name: "Other",
        count: 1,
      },
      {
        name: "Not applicable as we do not use these PGDs",
        count: 0,
      },
    ],
    total_answers: 1,
    total_answers_region: 0,
  },
  {
    question_text:
      "If your pharmacy is supplying antibiotics via PGD, do you supply self-care and safety netting advice to patients who are not given an antibiotic?",
    user_answer: "Not applicable (we do not use PGDs to supply antibiotics)",
    answers_count: [
      {
        name: "No",
        count: 0,
      },
      {
        name: "Not applicable (we do not use PGDs to supply antibiotics)",
        count: 1,
      },
      {
        name: "Yes",
        count: 0,
      },
    ],
    answers_count_region: [
      {
        name: "No",
        count: 0,
      },
      {
        name: "Not applicable (we do not use PGDs to supply antibiotics)",
        count: 1,
      },
      {
        name: "Yes",
        count: 0,
      },
    ],
    total_answers: 1,
    total_answers_region: 0,
  },
];

export const QUESTION_TYPES = {
    both: 'both',
    text: 'text',
    option: 'option'
}
