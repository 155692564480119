import React from "react";
import "./assessmentForm.css";

interface label_props {
  text: string;
  id: string;
  textSize?: string;
}

const InputLabel = ({ text, id, textSize }: label_props) => {
  return (
    <>
      <div className=" mb-2">
        <label
          htmlFor={id}
          className={`text-red ${textSize ? textSize : "text-sm"}`}
        >
          {text}
        </label>
      </div>
    </>
  );
};
export default InputLabel;
