import React from "react";
import LetsStart from "../components/views/LetsStart";
import FormPage from "../components/views/FormPage";
import { Navigate, Route, Routes } from "react-router-dom";
import DynamicComponent from "../components/views/DynamicComponent";
import { useAppSelector } from "../redux/hooks";
import ReportPage from "../components/views/ReportPage";
import HomePage1 from "../components/views/HomePage1";
import HomePage2 from "../components/views/HomePage2";

const PageRoutes: React.FC = () => {
  const allQuestions = useAppSelector(
    (state) => state.questionList.allQuestions
  );
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage1 />} />
        <Route path="/page-1" element={<HomePage1 />} />
        <Route path="/page-2" element={<HomePage2 />} />
        <Route path="/lets-start" element={<LetsStart />} />
        <Route path="*" element={<Navigate to="/" />} />
        {allQuestions.length > 0 && (
          <>
            <Route path="/form-page" element={<FormPage />} />
            <Route path="/questions" element={<DynamicComponent />} />
            <Route path="/report-page" element={<ReportPage />} />
          </>
        )}
      </Routes>
    </>
  );
};
export default PageRoutes;
