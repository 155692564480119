import React from "react";
import "./assessmentForm.css";
import logo from "../../assets/logo.png";

const Header = () => {
  return (
    <div className="mx-auto logo-div mb-2 sm:max-md:mt-3">
      <img src={logo} className="logo mx-auto" alt="Target healthcare logo" />
    </div>
  );
};
export default Header;
