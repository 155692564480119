import axios from "axios";

export const content_type = () => {
  return "application/json";
};

const hookRequestInterceptorsWithAxiosInstance = (instance: any) => {
  return instance.interceptors.request.use(
    // Any status code that lie within the range of 2xx cause this function to trigger

    (config: any) => {
      return config;
    }
  );
};

const hookResponseInterceptorsWithAxiosInstance = (instance: any) =>
  instance.interceptors.response.use(
    // Any status code that lie within the range of 2xx cause this function to trigger

    (response: any) => {
      return response;
    },

    // Any status codes that falls outside the range of 2xx cause this function to trigger

    (error: any) => {
      return Promise.reject(error);
    }
  );
function getAxios(tokenizeInstance: any, accessToken = null) {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
  });

  // Hooking a request interceptor

  hookRequestInterceptorsWithAxiosInstance(instance);

  // Hooking a response interceptor

  hookResponseInterceptorsWithAxiosInstance(instance);

  instance.defaults.headers["Content-Type"] = content_type();
  return instance;
}

axios.defaults.timeout = 10000;

export async function getMethod(type: string) {
  try {
    return await getAxios(true).get(type);
  } catch (error: any) {
    return error.response;
  }
}
export async function postMethod(type: string, data: any) {
  try {
    return await getAxios(true).post(type, data);
  } catch (error: any) {
    return error.response;
  }
}
