import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import { deepCopy, QUESTION_TYPES } from "../../../constants";

// Define a type for the slice state
interface QuestionListState {
  allQuestions: any;
  currentQuestionIndex: number;
  prevQuestionIndex: number | null;
  nextQuestionIndex: number | null;
  questionOverlay: boolean;
  textSubmitClick: boolean;
}

// Define the initial state using that type
const initialState: QuestionListState = {
  allQuestions: [],
  currentQuestionIndex: 0,
  prevQuestionIndex: null,
  nextQuestionIndex: 1,
  questionOverlay: false,
  textSubmitClick: false,
};

export const questionListSlice = createSlice({
  name: "questionList",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setQuestionOverlayTrue: (state) => {
      state.questionOverlay = true;
    },
    setQuestionOverlayFalse: (state) => {
      state.questionOverlay = false;
    },
    setIsEmptyTextSubmit: (state, action: PayloadAction<any>) => {
      state.textSubmitClick = action.payload;
    },
    emptyCategoryQuestion: (state) => {
      state.allQuestions = [];
    },
    updateCategoryQuestions: (state, action: PayloadAction<any>) => {
      let all_questions = deepCopy(action.payload);
      all_questions.map((q) => {
        q.text_response = "";
        return q.answers.map((answer: any) => {
          answer.is_answer = false;
          return answer;
        });
      });
      state.currentQuestionIndex = 0;
      state.prevQuestionIndex = null;
      state.nextQuestionIndex = 1;
      state.allQuestions = [...all_questions];
    },
    submitAnswerOption: (state, action: PayloadAction<string>) => {
      let arr = [...state.allQuestions];

      let currentQuestion = arr[state.currentQuestionIndex];
      if (
        currentQuestion.type.toLowerCase() !== QUESTION_TYPES.both ||
        (currentQuestion.type.toLowerCase() === QUESTION_TYPES.both &&
          action.payload !== currentQuestion.free_text_option)
      ) {
        state.questionOverlay = true;
      }
      if (
        currentQuestion.type.toLowerCase() === QUESTION_TYPES.both &&
        action.payload !== currentQuestion.free_text_option
      ) {
        currentQuestion.text_response = "";
      }
      currentQuestion.answers.map((item: any) => {
        if (item.answer === action.payload) {
          item.is_answer = true;
        } else {
          item.is_answer = false;
        }
        return item;
      });

      state.allQuestions = [...arr];
    },
    addTextareaAnswer: (state, action: PayloadAction<string>) => {
      let arr = [...state.allQuestions];

      let currentQuestion = arr[state.currentQuestionIndex];
      currentQuestion.text_response = action.payload;

      state.allQuestions = [...arr];
    },
    nextQuestionCall: (state) => {
      const all_questions = [...state.allQuestions];
      // simple flow at least 3 questions
      if (
        state.nextQuestionIndex != null &&
        state.currentQuestionIndex != null &&
        all_questions.length > state.nextQuestionIndex
      ) {
        // set question index state
        state.prevQuestionIndex != null
          ? (state.prevQuestionIndex += 1)
          : (state.prevQuestionIndex = 1);
        state.currentQuestionIndex += 1;
        state.nextQuestionIndex += 1;
      } else if (state.nextQuestionIndex === all_questions.length) {
      }
    },

    prevQuestionCall: (state) => {
      // simple flow at least 3 questions
      if (state.prevQuestionIndex != null && state.currentQuestionIndex) {
        // set question index state
        state.nextQuestionIndex != null
          ? (state.nextQuestionIndex = state.currentQuestionIndex)
          : (state.nextQuestionIndex = 1);
        state.currentQuestionIndex -= 1;
        state.prevQuestionIndex -= 1;
      }
    },
  },
});

export const {
  setQuestionOverlayTrue,
  setQuestionOverlayFalse,
  setIsEmptyTextSubmit,
  updateCategoryQuestions,
  emptyCategoryQuestion,
  nextQuestionCall,
  prevQuestionCall,
  submitAnswerOption,
  addTextareaAnswer,
} = questionListSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectQuestionList = (state: RootState) => state.questionList;

export default questionListSlice.reducer;
