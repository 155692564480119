import React from "react";
import "./assessmentForm.css";
import { useAppSelector } from "../../redux/hooks";

interface progressbar_props {
  page: number;
}

const ProgressBar = ({ page }: progressbar_props) => {
  const allQuestionsLength = useAppSelector(
    (state) => state.questionList.allQuestions.length
  );
  const arr = Array.from({ length: allQuestionsLength + 1 });
  return (
    <>
      <div
        className={`md:grid md:gap-2 md:grid-flow-col sm:max-md:flex sm:max-md:flex-wrap sm:max-md:justify-start`}
      >
        {arr.map((item, key) => {
          return (
            <div
              key={key}
              className={`bar-line md:min-w-4 sm:min-width-2 sm:max-md:m-2 ${
                key + 1 === page + 1
                  ? "bg-pink"
                  : key + 1 < page + 1
                  ? "bg-red"
                  : "bg-grey"
              } rounded-full`}
            ></div>
          );
        })}
      </div>
      <p className="text-black mt-4 text-sm">
        {" "}
        STEP {page + 1} OUT OF {allQuestionsLength + 1}
      </p>
    </>
  );
};
export default ProgressBar;
