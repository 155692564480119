import React, { ChangeEventHandler } from "react";
import "./assessmentForm.css";
import { useAppSelector } from "../../redux/hooks";

interface input_props {
  id: string;
  placeholder: string;
  required: boolean;
  type: string;
  value?: any;
  title?: string;
  onChangeHandler: ChangeEventHandler<HTMLInputElement>;
}

const InputElement = ({
  id,
  placeholder,
  required,
  type,
  value,
  title,
  onChangeHandler,
}: input_props) => {
  const submitLoader = useAppSelector((state) => state.userData.submitLoader);
  return (
    <>
      <input
        style={{
          borderColor:
            (!value?.toString().trim().length && submitLoader) ||
            (title === "post code" && value.length > 20 && submitLoader)
              ? "#AF1821"
              : "#DCDCDC",
        }}
        type={type}
        title={title ? title : id}
        min={0}
        value={value}
        onChange={(e) => onChangeHandler(e)}
        id={id}
        placeholder={placeholder}
        required={required}
        className="border rounded-lg md:text-xs sm:max-md:text-base w-full p-4 mb-2"
        pattern={`${type === "number" ? "d*" : ""}`}
        inputMode={`${type === "number" ? "decimal" : "text"}`}
      />
    </>
  );
};
export default InputElement;
