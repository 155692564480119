import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";

// Define a type for the slice state
interface AssessmentToolState {
  value: any;
  categoriesList: any;
}

// Define the initial state using that type
const initialState: AssessmentToolState = {
  value: { name: "GP practice", id: 2 },
  categoriesList: [],
};

export const assessmentToolSlice = createSlice({
  name: "assessmentTool",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setCategoriesList: (state, action: PayloadAction<any>) => {
      let list = [...action.payload];
      state.categoriesList = [...list];
    },
    setAssessmentTool: (state, action: PayloadAction<any>) => {
      let obj = {
        id: action.payload.id,
        name: action.payload.text,
      };
      state.value = obj;
    },
  },
});

export const { setAssessmentTool, setCategoriesList } =
  assessmentToolSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAssessmentTool = (state: RootState) =>
  state.assessmentTool.value;

export default assessmentToolSlice.reducer;
