import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
const { v4: uuidv4 } = require("uuid");

const UUID = uuidv4();
// Define a type for the slice state
interface UserDataState {
  uuid: string;
  email: string;
  postCode: string;
  practice: any;
  role: any;
  practiceList: any;
  roleList: any;
  regionList: any;
  region1List: any;
  regionBoardList: any;
  region1_option: any;
  region2_option: any;
  formCompletionCount: any;
  submitLoader: boolean;
  otherRole: string;
  otherPractice: string;
  showToaster: boolean;
  footerData: any
}

// Define the initial state using that type
const initialState: UserDataState = {
  uuid: UUID,
  email: "",
  postCode: "",
  practice: "",
  role: "",
  practiceList: [],
  roleList: [],
  regionList: [],
  region1List: [],
  regionBoardList: [],
  region1_option: "",
  region2_option: "",
  formCompletionCount: null,
  submitLoader: false,
  otherRole: "",
  otherPractice: "",
  showToaster: true,
  footerData: []
};

export const userDataSlice = createSlice({
  name: "userDara",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    showToasterContainer: (state, action: PayloadAction<any>) => {
      state.showToaster = action.payload;
    },
    updateBoardList: (state, action: PayloadAction<any>) => {
      state.regionBoardList = action.payload;
    },
    updateSubmitLoader: (state, action: PayloadAction<any>) => {
      state.submitLoader = action.payload;
    },
    reGenerateUUID: (state) => {
      state.uuid = uuidv4();
    },
    updatePracticeList: (state, action: PayloadAction<any>) => {
      state.practiceList = action.payload;
    },
    updateRolesList: (state, action: PayloadAction<any>) => {
      state.roleList = action.payload;
    },
    updateRegionList: (state, action: PayloadAction<any>) => {
      state.regionList = action.payload;
    },

    onChangeEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    onChangePostCode: (state, action: PayloadAction<string>) => {
      state.postCode = action.payload;
    },
    onChangeOtherRole: (state, action: PayloadAction<string>) => {
      state.otherRole = action.payload;
    },
    onChangeOtherPractice: (state, action: PayloadAction<string>) => {
      state.otherPractice = action.payload;
    },
    onChangePractice: (state, action: PayloadAction<string>) => {
      state.practice = action.payload;
      if (state.practice !== "5") {
        state.otherPractice = "";
      }
    },
    onChangeRole: (state, action: PayloadAction<string>) => {
      state.role = action.payload;
      if (state.role !== "9") {
        state.otherRole = "";
      }
    },
    onChangeRegion1: (state, action: PayloadAction<string>) => {
      if (state.region1_option === action.payload) {
        state.region1_option = action.payload;
      } else {
        state.region2_option = "";
      }
      state.region1_option = action.payload;
    },
    onChangeRegion2: (state, action: PayloadAction<string>) => {
      state.region2_option = action.payload;
    },
    onChangeFormCompletionCount: (state, action: PayloadAction<number>) => {
      if (action.payload >= 0 || !action.payload) {
        state.formCompletionCount = action.payload;
      } else {
        return;
      }
      state.formCompletionCount = action.payload;
    },
    setFooterData: (state, action: PayloadAction<string>) => {
      state.footerData = action.payload;
    },
  },
});

export const {
  showToasterContainer,
  onChangeEmail,
  onChangePostCode,
  onChangePractice,
  onChangeRole,
  onChangeOtherRole,
  onChangeOtherPractice,
  onChangeRegion1,
  updateBoardList,
  onChangeRegion2,
  onChangeFormCompletionCount,
  reGenerateUUID,
  updatePracticeList,
  updateRegionList,
  updateRolesList,
  updateSubmitLoader,
  setFooterData
} = userDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selecUserData = (state: RootState) => state.userData;

export default userDataSlice.reducer;
