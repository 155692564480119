import React from "react";
import "./assessmentForm.css";
import back_button_arrow from "../../assets/back_button_arrow.png";
import { useNavigate } from "react-router-dom";
import { prevQuestionCall } from "../../redux/features/questionList/questionListSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { showToasterContainer } from "../../redux/features/userData/userDataSlice";

interface button_props {
  to: string;
}
const BackButton = ({ to }: button_props) => {
  const navigate = useNavigate();
  const prevQuestionIndex = useAppSelector(
    (state) => state.questionList.prevQuestionIndex
  );
  const dispatch = useAppDispatch();
  const routeChange = () => {
    if (prevQuestionIndex == null || prevQuestionIndex === 0) {
      dispatch(showToasterContainer(false));
      navigate(to);
    } else {
      dispatch(showToasterContainer(false));
      dispatch(prevQuestionCall());
    }
  };
  return (
    <div className="flex justify-flex-start items-center" onClick={routeChange}>
      <img
        src={back_button_arrow}
        height={12}
        width={30}
        alt="navigate back icon"
      />
      <button
        className="btn btn-lg bg-white text-red pl-2"
        aria-label="navigate back button"
      >
        Back
      </button>
    </div>
  );
};
export default BackButton;
